<template>
    <v-dialog persistent width="25%" v-model="confirmationDialog">
        <v-card flat>
            <v-card-title class="justify-center"> Account Created Successfully </v-card-title>
            <v-card-actions class="justify-center">
                <v-btn @click="confirm()" color="primary">Go to Login</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import router from '../../router'
export default {
    name: "confirmationDialog",
    computed:{
        ...mapGetters(["confirmationDialog"])
    },
    methods:{
        confirm(){
            this.$store.commit("setConfirmationDialog", false)
            router.push('/login')
        }
    }
}
</script>