import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"25%"},model:{value:(_vm.confirmationDialog),callback:function ($$v) {_vm.confirmationDialog=$$v},expression:"confirmationDialog"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Account Created Successfully ")]),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirm()}}},[_vm._v("Go to Login")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }