<template>
    <v-dialog max-width="25%" persistent v-model="avatarListViewer">
        <v-card flat width="100%">
            <v-card-title class="justify-center">
                Selected Avatar List
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-center">
                        <v-btn color="primary" class="mt-2" @click="$store.commit('setSelectAvatarViewer', true)"><v-icon>mdi-plus</v-icon> Add Avatar</v-btn>
                    </v-col>
                    <v-col>
                        <v-list v-if="selectedAvatar.length > 0" height="360" class="overflow-y-auto">
                            <v-list-item v-for="(avatar, i) in selectedAvatar" :key="i">
                                <v-list-item-avatar>
                                    <v-img :src="getCurrentAvatarImage(avatar)"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content class="ml-10">
                                    <v-list-item-title>{{ avatar.avatarName }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon @click="deleteAvatar(avatar.avatarId)">
                                    <v-icon color="red">
                                        mdi-delete
                                    </v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn width="150" color="primary" @click="saveUpdates()"><v-icon class="mx-1">mdi-content-save</v-icon>Save</v-btn>
                <v-btn width="150" color="error" @click="closeDialog()">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "avatarListViewer",
    computed: {
        ...mapGetters(["avatarListViewer", "selectedAvatar", "selectedKiosk", "avatarList"])
    },
    methods: {
        closeDialog() {
            this.$store.commit('setAvatarListViewer', false)
        },
        getCurrentAvatarImage(avatar) {
            if (avatar !== undefined) {
                this.avatarName = avatar.avatarName
                let temp = this.avatarList.filter(ava => ava.avatarId === avatar.avatarId)
                return 'https://strapi.arsconnect.com' + temp[0].avatarImage.url
            }
        },
        deleteAvatar(id) {
            let index = this.selectedAvatar.findIndex(ava => ava.avatarId === id)
            this.$store.commit("removeAvatar", index)
        },
        saveUpdates(){
            let data = {
                kioskId: this.selectedKiosk.id,
                avatarList: this.selectedAvatar
            }
            this.$store.dispatch("updateAvatarList", data)
        }
    },
    watch: {
        selectedKiosk(newVal) {
            console.log('Selected Kiosk: ', newVal.data())
            if (newVal.data().avatarInfo.length > 0) {
                newVal.data().avatarInfo.forEach(avatar => {
                    console.log('What avatar do I have: ', avatar)
                    this.$store.commit('setSelectedAvatar', avatar)
                });
            }
        }
    },
    mounted() {
        //console.log('Selected Kiosk? :' , this.selectedKiosk.data())
    }
}
</script>