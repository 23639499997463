import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"25%","persistent":""},model:{value:(_vm.avatarListViewer),callback:function ($$v) {_vm.avatarListViewer=$$v},expression:"avatarListViewer"}},[_c(VCard,{attrs:{"flat":"","width":"100%"}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Selected Avatar List ")]),_c(VDivider),_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$store.commit('setSelectAvatarViewer', true)}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Add Avatar")],1)],1),_c(VCol,[(_vm.selectedAvatar.length > 0)?_c(VList,{staticClass:"overflow-y-auto",attrs:{"height":"360"}},_vm._l((_vm.selectedAvatar),function(avatar,i){return _c(VListItem,{key:i},[_c(VListItemAvatar,[_c(VImg,{attrs:{"src":_vm.getCurrentAvatarImage(avatar)}})],1),_c(VListItemContent,{staticClass:"ml-10"},[_c(VListItemTitle,[_vm._v(_vm._s(avatar.avatarName))])],1),_c(VListItemIcon,{on:{"click":function($event){return _vm.deleteAvatar(avatar.avatarId)}}},[_c(VIcon,{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1)],1)}),1):_vm._e()],1)],1)],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"width":"150","color":"primary"},on:{"click":function($event){return _vm.saveUpdates()}}},[_c(VIcon,{staticClass:"mx-1"},[_vm._v("mdi-content-save")]),_vm._v("Save")],1),_c(VBtn,{attrs:{"width":"150","color":"error"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }