import * as fb from "../../firebase/index.js";
import router from "../../router";
import axios from "axios";
export default {
  state: {
    incomingCallDialog: {
      show: false,
      meeting: {},
    },
    callId: null,
    incomingCallListener: undefined,
    ringer: new Audio(
      "https://cms.ottawa.arsconnect.com/uploads/ring_7a6e3357c1.mp3"
    ),
  },
  getters: {
    getIncomingCallDialog: (state) => state.incomingCallDialog,
    getCallId: (state) => state.callId,
    getIncomingCallListener: (state) => state.incomingCallListener,
    getRinger: (state) => state.ringer,
  },
  mutations: {
    setIncomingCallDialog(state, obj) {
      state.incomingCallDialog.show = obj.show;
      state.incomingCallDialog.meeting = obj.meeting;
    },

    closeIncomingCallDialog(state) {
      state.incomingCallDialog.show = false;
      state.incomingCallDialog.meeting = {};
    },
    setCallId(state, id) {
      state.callId = id;
    },
  },
  actions: {
    handleIncomingRequest({ commit, getters, dispatch, state }) {
      console.log('USer ID: ', getters.userId)
      state.incomingCallListener = fb.telepresenceCollection
        .where("requestedUser", "array-contains", getters.userId)
        .onSnapshot((calls) => {
          // Session ID is set in Manage user presence in auth.js
          let sessionID, broswerSessionID;
          console.log("Session ID: ", getters.userProfile.data());
          if (calls.docs.length > 0) {
            sessionID = getters.userProfile.data().activeSession.toString();
            broswerSessionID = window.sessionStorage
              .getItem("sessionID")
              .toString();
          }
          if (sessionID === broswerSessionID) {
            calls.docChanges().forEach((change) => {
              if (change.type === "removed") {
                state.ringer.pause();
                if (getters.getIncomingCallDialog.show) {
                  commit("setCallStatusDialog", {
                    show: true,
                    message: "Missed Call from " + change.doc.data().caller,
                  });

                  commit("closeIncomingCallDialog");
                }
              } else if (change.type === "added") {
                console.log("coming here in added.", change.doc);
                dispatch("callHandlingIncoming", change.doc);
                state.ringer.currentTime = 0;
                state.ringer.loop = true;

                // Adding notification here
                if (!("Notification" in window)) {
                  alert("This browser does not support desktop notification");
                } else if (Notification.permission === "granted") {
                  var notification = new Notification(
                    "Incoming Call Ottawa Dashboard",
                    {
                      body:
                        "Receiving Call from " +
                        change.doc.data().callInitiator.name,
                    }
                  );
                } else if (Notification.permission !== "denied") {
                  Notification.requestPermission().then(function (permission) {
                    if (permission === "granted") {
                      var notification = new Notification(
                        "Incoming Call Ottawa Dashboard",
                        {
                          body:
                            "Receiving Call from " +
                            change.doc.data().callInitiator.name,
                        }
                      );
                    }
                  });
                }
              } else {
                state.ringer.pause();
                dispatch("callHandlingIncoming", change.doc);
              }
            });
          }
        });
    },

    // handleIncomingExistingRequest({ commit, getters, dispatch, state }) {
    //   state.incomingCallListener = fb.telepresenceCollection
    //     .where("addToExistingCallUsers", "==", getters.userId)
    //     .onSnapshot((calls) => {
    //       // Session ID is set in Manage user presence in auth.js
    //       let sessionID, broswerSessionID;
    //       console.log("Calls added to the DB: ", calls.docs.length);
    //       if (calls.docs.length > 0) {
    //         sessionID = getters.userProfile.data().activeSession.toString();
    //         broswerSessionID = window.sessionStorage
    //           .getItem("sessionID")
    //           .toString();
    //       }
    //       if (sessionID === broswerSessionID) {
    //         calls.docChanges().forEach((change) => {
    //           if (change.type === "removed") {
    //             state.ringer.pause();
    //             if (getters.getIncomingCallDialog.show) {
    //               commit("setCallStatusDialog", {
    //                 show: true,
    //                 message: "Missed Call from " + change.doc.data().caller,
    //               });

    //               commit("closeIncomingCallDialog");
    //             }
    //           } else if (change.type === "added") {
    //             console.log("Coming in here to new call added", change.doc);
    //             dispatch("callHandlingIncoming", change.doc);
    //             state.ringer.currentTime = 0;
    //             state.ringer.loop = true;
    //             try {
    //               state.ringer.play();
    //             } catch (e) {
    //               //console.log(e.message)
    //             }

    //             // Adding notification here
    //             if (!("Notification" in window)) {
    //               alert("This browser does not support desktop notification");
    //             } else if (Notification.permission === "granted") {
    //               var notification = new Notification(
    //                 "Incoming Call Dekalb Dashboard",
    //                 { body: "Receiving Call from " + change.doc.data().caller }
    //               );
    //             } else if (Notification.permission !== "denied") {
    //               Notification.requestPermission().then(function (permission) {
    //                 if (permission === "granted") {
    //                   var notification = new Notification(
    //                     "Incoming Call Dekalb Dashboard",
    //                     {
    //                       body:
    //                         "Receiving Call from " + change.doc.data().caller,
    //                     }
    //                   );
    //                 }
    //               });
    //             }

    //             notification.onclick = function (event) {
    //               window.parent.parent.focus();
    //             };
    //           } else {
    //             //state.ringer.pause();
    //             dispatch("callHandlingIncoming", change.doc);
    //           }
    //         });
    //       }
    //     });
    // },

    callHandlingIncoming({ commit, getters, state, dispatch }, callStatus) {
      switch (callStatus.data().status) {
        case "Initiated":
          state.ringer.play();
          commit("setIncomingCallDialog", {
            show: true,
            meeting: {
              callerInfo: callStatus.data().caller,
              meetingCode: callStatus.data().meetingCode,
              meetingId: callStatus.id,
              initiatorName: callStatus.data().caller.kioskName,
              callStatus: callStatus.data().callStatus,
            },
          });
          commit("setCallId", callStatus.id);
          break;
        case "Ended":
          dispatch("setUserOffCall");
          router.push("/");
          break;
        case "Declined":
          //
          break;
        case "inProgress":
          console.log("Call in progress: Ringer should stop.");
          state.ringer.pause();
          break;
        case "addToExisting":
          console.log(
            "Call Status at Handle incoming call: ",
            callStatus.data()
          );
          commit("setIncomingCallDialog", {
            show: true,
            meeting: {
              callerInfo: callStatus.data().caller,
              meetingCode: callStatus.data().meetingCode,
              meetingId: callStatus.id,
              initiatorName: callStatus.data().caller,
              callStatus: callStatus.data().callStatus,
            },
          });
          commit("setCallId", callStatus.id);
          break;
        case "addToExistingInProgress":
          clearTimeout(getters.getCallEndTimer);
          commit("setAddToCallViewer", {
            show: false,
          });
          break;
        case "addToExistingRejected":
          clearTimeout(getters.getCallEndTimer);
          commit("setAddToCallViewer", {
            show: false,
          });
          commit("setAddToCallStatus", {
            show: true,
            message: "Requested user rejected the call.",
          });
          break;
        case "addToExistingCancelled":
          clearTimeout(getters.getCallEndTimer);
          commit("setAddToCallViewer", {
            show: false,
          });
          break;
        case "addToExistingNotAsnwered":
          commit("setAddToCallViewer", {
            show: false,
          });
          commit("setAddToCallStatus", {
            show: true,
            message: "Requested user did not answer the call.",
          });
          break;
        default:
        //
      }
    },
  },
};
