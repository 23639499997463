import * as fb from '@/firebase/index.js'

export default {
  state: {
    snackBar: {},
    appData: {},
    announcement: {},
    showAlert: true,
    confirmationDialog: false
  },
  getters: {
    snackBar: state => state.snackBar,
    navBar: state => state.navBar,
    appData: state => state.appData,
    announcement: state => state.announcement,
    showAlert: state => state.showAlert,
    confirmationDialog: state => state.confirmationDialog
  },
  mutations: {
    setSnackBar(state, snackBar) {
      state.snackBar = snackBar;
    },
    setAppData(state, appData) {
      state.appData = appData;
    },
    setAnnouncement(state, announcement) {
      state.announcement = announcement;
    },
    setShowAlert(state, status){
      state.showAlert = status
    },
    setConfirmationDialog(state, status){
      state.confirmationDialog = status
    }
  },
  actions: {
    //check if the application is executing for the first time
    getAppData({ commit }) {
      return new Promise((resolve, reject) => {
        fb.appDataCollection.get('config')
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              commit('setAppData', { initiated: false });
              resolve('not initiated')
            }
            else {
              fb.appDataCollection.doc('config').onSnapshot(appData => {
                commit('setAppData', appData.data());
                resolve('fetched');
              })
            }
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    initiateAppData() {
      return new Promise((resolve, reject) => {
        fb.appDataCollection.get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              fb.appDataCollection.doc('config').set({
                initiated: true,
                themes: {
                  light: {
                    primary: '#1976D2',
                    secondary: '#424242',
                    accent: '#82B1FF',
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FFC107',
                    navBarColor: '#1976D2',
                  },
                },
                authentication: {
                  configured: false,
                  type: '',
                  domain: ''
                },
                telepresence: false,
                configured: false
              }).then(() => {
                resolve('initiated');
              })
            }
          })
      })
    },
    showSnackBar({ commit }, snackBar) {
      snackBar.show = true;
      snackBar.color = (snackBar.color === undefined) ? 'grey darken-2' : snackBar.color;
      snackBar.timeout = (snackBar.timeout === undefined) ? 5000 : snackBar.timeout;
      snackBar.icon = (snackBar.icon === undefined) ? 'mdi-information-outline' : snackBar.icon;
      commit('setSnackBar', snackBar);
    },
    showAnnouncement({ commit }, announcement) {
      announcement.show = true;
      announcement.color = (announcement.color === undefined) ? 'amber darken-1' : announcement.color;
      announcement.timeout = (announcement.timeout === undefined) ? 0 : announcement.timeout;
      announcement.icon = (announcement.icon === undefined) ? 'mdi-information-outline' : announcement.icon;
      commit('setAnnouncement', announcement);
    },
    
  }
}