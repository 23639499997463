import * as fb from "../../firebase/index.js";
import axios from "axios";
import { reject } from "lodash";
export default {
  state: {
    userGroups: [],
    // Add New Group Dialog
    addNewGroupDialog: false,
  },
  getters: {
    userGroups: (state) => state.userGroups,
    getNewGroupDialog: (state) => state.addNewGroupDialog,
  },
  mutations: {
    setUserGroups(state, userGroup) {
      state.userGroups.push(userGroup);
    },
    setNewGroupDialog(state, status) {
      state.addNewGroupDialog = status;
    },
  },
  actions: {
    getUserGroups({ commit, getters }) {
      let groups = [];
      return new Promise((resolve, reject) => {
        let grpList;
        if (getters.isSuperAdmin || getters.isClientAdmin) {
          grpList = fb.userGroupsCollection;
        } else {
          grpList = fb.userGroupsCollection.where(
            "kioskDepartment",
            "==",
            getters.userProfile.data().courthouse
          );
        }
        grpList.onSnapshot((querySnapshot) => {
          groups = [];
          if (querySnapshot.empty) {
            console.log("Empty group collection");
            resolve("Empty group collection");
          } else {
            querySnapshot.forEach((group) => {
              let data = group.data();
              data["id"] = group.id;
              let index = getters.userGroups.findIndex(
                (grp) => grp.id === group.id
              );
              if (index !== -1) {
                getters.userGroups.splice(index, 1);
              }
              commit("setUserGroups", data);
            });
          }
          resolve("Feteched all user groups");
        });
      });
    },
    removeGroupFromArray({ getters }, id) {
      let index = getters.userGroups.findIndex((grp) => grp.id === id);
      if (index !== -1) {
        getters.userGroups.splice(index, 1);
      }
    },
    addNewGroup({ dispatch, getters }, group) {
      return new Promise((resolve, reject) => {
        console.log("At add new group: ", group);
        const newGroup = {
          name: group.name,
          description: group.description,
          dateCreated: new Date(),
          lastUpdated: new Date(),
          userCount: group.users.length,
          users: group.users,
          kiosks: group.kiosks,
          defaultTelepresence: group.defaultTelepresence,
          kioskDepartment: group.kioskDepartment,
        };
        fb.userGroupsCollection
          .add(newGroup)
          .then((response) => {
            dispatch("getUserGroups");
            resolve("New group added successfully");
            // Add group ID to kiosk and user
            dispatch("addGroupToUser", {
              users: group.users,
              id: response.id,
            });
            dispatch("addGroupToKiosk", {
              kiosks: group.assignedKiosks,
              id: response.id,
            });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    addGroupToUser({ getters }, group) {
      group.users.forEach((user) => {
        fb.registeredUsersCollection.doc(user).update({
          groups: fb.firebase.firestore.FieldValue.arrayUnion(group.id),
        });
      });
    },
    addGroupToKiosk({ getters }, group) {
      group.kiosks.forEach((kiosk) => {
        fb.kioskCollection.doc(kiosk).update({
          groups: fb.firebase.firestore.FieldValue.arrayUnion(group.id),
        });
      });
    },
    deleteGroupFromUser({ getters }, userObj) {
      console.log("User Obj on update Group: ", userObj);
      userObj.removedUsers.forEach((user) => {
        fb.registeredUsersCollection.doc(user).update({
          groups: fb.firebase.firestore.FieldValue.arrayRemove(userObj.groupId),
        });
      });
    },
    deleteGroupFromKiosk({ getters }, kioskObj) {
      kioskObj.removedKiosks.forEach((kiosk) => {
        fb.kioskCollection.doc(kiosk).update({
          groups: fb.firebase.firestore.FieldValue.arrayRemove(
            kioskObj.groupId
          ),
        });
      });
    },
    updateGroup({ dispatch, getters }, group) {
      return new Promise((resolve, reject) => {
        fb.userGroupsCollection
          .doc(group.id)
          .update({
            name: group.name,
            description: group.description,
            lastUpdated: new Date(),
            userCount: group.users.length,
            users: group.users,
            kiosks: group.kiosks,
            defaultTelepresence: group.defaultTelepresence,
          })
          .then(() => {
            resolve("User group updated successfully.");
            dispatch("addGroupToUser", {
              users: group.users,
              id: group.id,
            });
            dispatch("addGroupToKiosk", {
              kiosks: group.kiosk,
              id: group.id,
            });
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    deleteGroup({ dispatch, getters }, groupId) {
      return new Promise((resolve, reject) => {
        fb.userGroupsCollection
          .doc(groupId.id)
          .delete()
          .then(() => {
            let index = getters.userGroups.findIndex(
              (grp) => grp.id === groupId.id
            );
            if (index !== -1) {
              getters.userGroups.splice(index, 1);
            }
            const config = {
              method: "post",
              url: "https://us-central1-maryland-eb75e.cloudfunctions.net/apiV2/deleteUserGroup/a763cd056f1b2405788443b7197e0708",
              params: {
                users: groupId.users,
                groupName: groupId.id,
                kiosk: groupId.kiosks,
              },
            };
            axios(config)
              .then((result) => {
                console.log("Result from axios call", result);
              })
              .catch((error) => {
                console.log("Error : ", error.message);
              });
            //dispatch('getUserGroups');
            resolve("Group deleted successfully.");
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    // removeDeletedUserFromGroup({ state }, user) {
    //     console.log('User: ', user)
    //     user.groups.forEach(group => {
    //         fb.userGroupsCollection.doc(group).update({
    //             users: fb.firebase.firestore.FieldValue.arrayRemove(
    //                 user.id
    //             )
    //         })
    //     })
    // },

    // removeDeletedKioskFromGroup({ state }, kiosk) {
    //     console.log('Kiosk: ', kiosk.data())
    //     kiosk.data().groups.forEach(group => {
    //         fb.userGroupsCollection.doc(group).update({
    //             kiosk: fb.firebase.firestore.FieldValue.arrayRemove(
    //                 kiosk.id
    //             )
    //         })
    //     })
    // }
  },
};
