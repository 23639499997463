import { ApolloClient } from 'apollo-client';
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import router from '../../router';
import {
    avatarQuery,
    langCode,
    singleAvatarQuery
} from '@/graphql/query';
export default {
    state: {
        dataLoaded: false,
        isGuest: false,
        apolloClient: undefined,
        avatarList: [],
        langCodes: []
    },
    getters: {
        getDataLoaded: state => state.dataLoaded,
        isGuest: state => state.isGuest,
        avatarList: state => state.avatarList,
        langCodes: state => state.langCodes
    },
    mutations: {
        setDataLoaded(state, status) {
            state.dataLoaded = status
        },
        setIsGuest(state, status) {
            state.isGuest = status
        },
        setAvatarList(state, avatar) {
            state.avatarList.push(avatar)
        },
        setLangCodes(state, codes) {
            state.langCodes.push(codes)
        },
        setApolloClient(state, uri) {
            const fragmentMatcher = new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [],
                    },
                },
            });
            state.apolloClient = new ApolloClient({
                link: new HttpLink({ uri: uri }),
                cache: new InMemoryCache({ fragmentMatcher })
            })
        },
    },
    actions: {
        loadSuperAdminData({ dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('getUserRoles').then(() => {
                    dispatch('getAllRegisteredUsers').then(() => {
                        dispatch('getAllRegisteredKiosks').then(response => {
                            dispatch("getUserGroups").then(() => {
                                resolve(true)
                            })
                        })
                    })
                },
                    error => {
                        reject(false)
                    }
                )
            })

        },
        loadClientAdminData({ dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('getUserRoles').then(() => {
                    dispatch('getAllRegisteredKiosks').then(() => {
                        dispatch('getUserGroups').then(() => {
                            dispatch('getAllRegisteredUsers').then(() => {
                                resolve(true)
                            })
                        })
                    })
                },
                    error => {
                        reject(false)
                    })
            })
        },
        loadGeneralUserData({ dispatch }) {
            return new Promise((resolve, reject) => {
                dispatch('getUserRoles').then(() => {
                    dispatch('getAllRegisteredKiosks').then(() => {
                        dispatch('getUserGroups').then(() => {
                            dispatch('getAllRegisteredUsers').then(() => {
                                resolve(true)
                            })
                        })
                    })
                },
                    error => {
                        reject(false)
                    })
            })
        },
        loadData({ dispatch, commit, getters }, role) {
            if (!getters.getDataLoaded) {
                router.push('/')
                dispatch('createApolloConnection').then(response => {
                    dispatch('loadAvatarList').then(response => {
                        dispatch('loadlangCodeList')
                    })
                })
                dispatch('pullClientConfiguration')
                if (getters.isSuperAdmin) {
                    dispatch("loadSuperAdminData").then(response => {
                        if (response) {
                            setTimeout(() => { commit('setDataLoaded', true) }, 1000)
                        }
                    })
                } else if (getters.isAdmin) {
                    dispatch('loadClientAdminData').then(response => {
                        if (response) {
                            setTimeout(() => { commit('setDataLoaded', true) }, 1000)
                        }
                    })
                } else {
                    dispatch('loadGeneralUserData').then(response => {
                        if (response) {
                            setTimeout(() => { commit('setDataLoaded', true) }, 1000)
                        }
                    })
                }
            }
        },
        createApolloConnection({ commit }, uri) {
            return new Promise((resolve, reject) => {
                if (uri === '' || uri === null) reject('Empty or Invalid URI')
                try {
                    commit('setApolloClient', 'https://strapi.arsconnect.com/graphql')
                    resolve('Apollo linked successfully');
                } catch (err) {
                    reject(err.message);
                }
            })
        },
        loadAvatarList({ commit, state }) {
            return new Promise((resolve, reject) => {
                state.apolloClient.query({
                    query: avatarQuery
                }).then(avatars => {
                    avatars.data.avatars.forEach(avatar => {
                        commit('setAvatarList', avatar)
                    })
                    resolve('Avatars fetched successfully')
                }), error => {
                    reject(error.message)
                }
            })
        },
        loadlangCodeList({ commit, state }) {
            return new Promise((resolve, reject) => {
                state.apolloClient.query({
                    query: langCode
                }).then(languageCodes => {
                    languageCodes.data.languageCodes.forEach(code => {
                        let data = {
                            avatarGender: code.avatarGender,
                            voiceName: code.voiceName,
                            language: code.language,
                            langCode: {
                                langID: code.langID,
                                voiceID: code.voiceID,
                                engineID: code.engineID
                            }
                        }
                        commit('setLangCodes', data)
                    })
                    resolve('Avatars fetched successfully')
                }), error => {
                    reject(error.message)
                }
            })
        },
    }
}