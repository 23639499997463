import * as fb from "../../firebase";
export default {
    state: {
        clientConfig: null
    },
    getters: {
        getClientConfig: state => state.clientConfig
    },
    mutations: {
        setClientConfig(state, config) {
            state.clientConfig = config
        }
    },
    actions: {
        saveClientConfiguration({ commit, getters }, config) {
            fb.clientConfigCollection.doc('config').set({
                courtInfo: config.courtInfo,
                languages: config.languages,
                modules: config.modules,
                telepresence: config.telepresence,
                kioskRoles: config.kioskRoles
            }).then(() => {
                fb.appDataCollection.doc("config").update({
                    configured: true
                })
            })
        },
        updateClientConfiguration({ dispatch, getters }, config) {
            fb.clientConfigCollection.doc("config").update({
                courtInfo: config.courtInfo,
                languages: config.languages,
                modules: config.modules,
                telepresence: config.telepresence,
                kioskRoles: config.kioskRoles
            }).then(() => {
                dispatch("pullClientConfiguration")
                if (getters.allRegisteredKiosks.length > 0) {
                    getters.allRegisteredKiosks.forEach(kiosk => {
                        fb.kioskCollection.doc(kiosk.id).update({
                            teleDepartment: config.teleDepartment
                        })
                    })
                }
            })

        },
        pullClientConfiguration({ commit }) {
            fb.clientConfigCollection.get().then(config => {
                config.docs.forEach(doc => {
                    commit('setClientConfig', doc.data())
                });
            })
        }
    }
}