<template>
    <v-dialog width="35%" persistent v-model="updateAvatarConfirm.show">
        <v-card flat>
            <v-card-title class="justify-center">
                Update Avatar Confirmation
            </v-card-title>
            <v-card-actions class="justify-center">
                <v-btn color="primary" width="150"
                    @click="confirmAvatarChange(); $store.commit('setUpdateAvatarConfirm', { show: false })" class="mx-2">
                    Update Avatar </v-btn>
                <v-btn color="error" width="150" @click="$store.commit('setUpdateAvatarConfirm', { show: false })"
                    class="mx-2">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "updateAvatarConfirmation",
    computed: {
        ...mapGetters(['updateAvatarConfirm'])
    },
    methods: {
        confirmAvatarChange() {
            console.log('Avatar Info: ', this.updateAvatarConfirm)
            // let data = {
            //     kioskId: this.updateAvatarConfirm.info.kioskId,
            //     sitepalId: this.updateAvatarConfirm.info.sitepalId,
            //     avatarId: this.updateAvatarConfirm.info.avatarId,
            //     avatarName: this.updateAvatarConfirm.info.avatarName,
            //     langCodes: this.updateAvatarConfirm.info.langCodes
            // }
            //this.$store.commit("setSelectedAvatar", data)
            //this.$store.dispatch('updateAvatarConfig', data)
        }
    }
}
</script>